@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.body {
  padding-left: 3rem;
  padding-right: 3rem;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.28581;
  text-transform: none;
  color: #1c2127;
  font-family: "Roboto", sans-serif;
}

footer {
  /* display: flex;

  border-top: 1px solid white;
  justify-content: center;
  align-items: center; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0.5rem;
}

.button-styled-as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.smaller-header-text {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.container-pdf {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.floatLeft {
  float: left;
}

.scroll {
  max-height: 500px;
  overflow-y: scroll;
}

.scroll-update {
  max-height: 750px;
  overflow-y: scroll;
}

.homeIcons {
  width: 3rem;
  margin-right: 1rem;
  color: #0593ff;
  stroke: black;
  stroke-width: 2rem;
}

.floatLeftWithMargin {
  float: left;
  margin-top: 30px;
}

.floatRightWithMargin {
  float: right;
  margin-bottom: 10px;
}

.floatRightNoMargin {
  float: right;
}

.floatLeftWithSlightMargin {
  float: left;
  margin-top: 15px;
}

.medText {
  font-size: small;
}

.marginLeft70 {
  margin-left: 70px;
}

.feedbackSummaryCard {
  background-color: #3c5e73;
  color: #f0f0f0;
}

.feedbackPointsGreen {
  text-align: center;
  margin-top: 30px;
  font-size: 3rem;
  color: #83f883;
}

.feedbackPointsAmber {
  text-align: center;
  margin-top: 30px;
  font-size: 3rem;
  color: #f8a32c;
}

.feedbackPointsRed {
  text-align: center;
  margin-top: 30px;
  font-size: 3rem;
  color: red;
}

.feedbackChart {
  margin-left: 80px;
}

.buttons-container {
  display: flex;
  margin: auto;
  justify-content: center;
}

.bannerStretch {
  width: 100%;
}

.mailIcon {
  margin-right: 1rem;
  margin-top: 0rem;
}

.profileDetails {
  display: inline-block;
  margin-left: 20px;
  margin-top: 20px;
}

.dotGreen {
  height: 80px;
  width: 80px;
  background-color: #00ff15;
  border-radius: 50%;
  display: inline-block;
  margin-left: 50px;
  margin-top: 20px;
}

th.desc {
  border-bottom: 5px solid rgb(136, 136, 255);
}

th.asc {
  border-top: 5px solid orange;
}

.homeMessage {
  color: rgb(121, 46, 144);
  text-shadow: 1px 1px 1px #ccc;
}

.gsheetsViewer {
  width: 100%;
  height: 600px;
}

/* ... */
.loader {
  margin: 100px auto;
}

/* ... */
.user-details {
  margin-top: 20px;
}

.marginTop75 {
  margin-top: 75px;
}

.specialCursor {
  cursor: pointer;
}

/* ... */
.feedbackRating {
  margin-top: 10px;
}

.feedbackContainer {
  margin-top: 2rem;
}

.feedbackControls {
  width: 80%;
}

.feedbackControlsViewEntry {
  margin-top: 3rem;
  margin-left: 3rem;
}

.slightMargin {
  margin-top: 2rem;
}

.smallMargin {
  margin-top: 3rem;
}

.medMargin {
  margin-top: 4.5rem;
}

.medMargin2 {
  margin-top: 4.5rem;
}

.slightMarginBottom {
  margin-bottom: 2rem;
}

.user-actions {
  display: flex;
  flex-direction: column;
}

.headerImage {
  margin-right: 20px;
}

.mainHeading {
  margin-left: 160px;
}

.splashHeader {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  text-align: center;
}

.loginRegisterCard {
  margin: auto;
  width: 25rem;
  padding: 10px;
  text-align: center;
}

.profilePhoto {
  float: left;
  width: 250px;
}

.active {
  background-color: rgb(121, 46, 144);
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

.navbar-nav > li {
  padding-left: 30px;
  padding-right: 30px;
}

.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:visited {
  background-color: rgb(121, 46, 144) !important;
  color: rgb(255, 255, 255) !important;
  border-style: none;
}

.marginLeftHeading {
  margin-left: 5.5rem;
}

.photoshootContainer {
  border-style: groove;
  padding: 20px;
}

.photoshootDetails {
  margin-top: 3rem;
}

.photoshootHeading {
  margin-top: 2rem;
}

table {
  font-family: "Roboto", sans-serif;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For React PDF Responsiveness */
#ResumeContainer {
  margin: auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFDocument {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFPage {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.PDFPageOne {
  margin-bottom: 25px;
}

.PDFPage > canvas {
  max-width: 100%;
  height: auto !important;
}
/* END For React PDF Responsiveness */

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 1em;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table th,
.styled-table {
  padding: 12px 15px;
}

.styled-table td {
  font-size: 1.5em;
}

.stats-table {
  font-size: 1.2em;
}

.message-text {
  font-size: 1.5em;
  white-space: break-spaces;
}

.auth-form-spacing {
  padding: 10px;
}

.fcd-logo {
  width: 75px;
}

a.dropdown-toggle {
  text-decoration: none;
}

.nav-item .dropdown-menu {
  background: rgb(33, 37, 41);
}

.nav-item .dropdown-item {
  color: #ffffff;
}

.nav-item .dropdown-item:hover {
  background: #f0ad4e;
}

.--bs-nav-link-color {
  color: #ffffff;
}

nav .navbar-nav li a {
  color: white !important;
}

tbody tr:hover {
  text-decoration: underline;
  cursor: pointer;
}
